import React from "react";
import { AppState } from "../../store/reducer";
import { connect } from "react-redux";
import { Data } from "../../framework/data";
import { LeagueHeaderWithCurrentUserAssignment } from "../../store/leagues/models";
import { loadOpenLeagues } from "../../store/leagues/actions";
import { Dispatch } from "redux";
import { openLeaguesByCity } from "../../store/leagues/selectors";
import LeagueGroups from "./LeagueGroups";
import { Player } from "../../store/players/models";
import { currentPlayer } from "../../store/players/selectors";
import { getConfig } from "../../config/configuration";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { Typography, Grid, Badge, Button, useTheme, useMediaQuery, Select, MenuItem, Link } from "@material-ui/core";
import DescriptionIcon from '@material-ui/icons/Description';
import { Alert, AlertTitle } from "@material-ui/lab";
import { withRouter, match } from "react-router-dom";

interface LeaguePageProps {
  match: match<{ year: string }>;
  leagues?: Data<{ [key: string]: LeagueHeaderWithCurrentUserAssignment[] }>;
  player?: Data<Player>;
  loadLeagues?: (year?: string) => void;
}

const LeaguesPage: React.FC<LeaguePageProps> = ({
  match,
  leagues,
  loadLeagues,
}) => {

  var theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [availableYears, setAvailableYears] = React.useState<string[]>([]);
  const [defaultSeason, setDefaultSeason] = React.useState<string>("2023-3");

  const season = match.params.year;
 
  const setViewedYear = React.useCallback((year: string) => window.location.assign(`/#/leagues/${year}`), []);

  React.useEffect(() => {
    getConfig().then(({ seasons, defaultSeason }) => {
      setAvailableYears(seasons);
      setDefaultSeason(defaultSeason);
    });
  }, []);

  React.useEffect(() => {
    loadLeagues && loadLeagues(season);
  }, [season]);

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Alert severity="info" style={{marginBottom: 16, maxWidth: '84vw'}}>
          <AlertTitle>Sezon 2025/1</AlertTitle>
          <Typography variant="body2">Sezon 2025/1 startuje w Krakowie 6 stycznia! Możesz dołączyć do ligi klikając na przycisk poniżej.</Typography>
        </Alert>
        <Button color="primary" variant="contained" href="/#/signup" startIcon={
          <DescriptionIcon />
        }>Zapisz się</Button>
      </Grid>
      <Grid item>
        <LeagueGroups
          groups={leagues!}
        ></LeagueGroups>

        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="h6">Sezony:</Typography>
          </Grid>
          <Grid item>
            {
              isSmall ? (
                <Select value={season} onChange={(evt: React.ChangeEvent<any>) => { setViewedYear(evt.target.value) }} variant="outlined">
                  {
                    availableYears.map((y, i) => (
                      <MenuItem key={i} value={y}>
                        {y.replace("-", "/")}
                        {
                          i === 0 && y !== defaultSeason ? (<Badge color="primary" badgeContent={"!"}><>&nbsp;</></Badge>) : (<></>)
                        }
                      </MenuItem>
                    ))
                  }
                </Select>
              ) : (
                  <ToggleButtonGroup
                    value={season}
                    exclusive
                    onChange={(_, v) => { setViewedYear(v) }}
                  >
                    {availableYears.map((y, i) => (
                      <ToggleButton key={y} value={y}>
                        {y.replace("-", "/")}
                        {
                          i === 0 && y !== defaultSeason ? (<Badge color="primary" badgeContent={"!"}><>&nbsp;</></Badge>) : (<></>)
                        }
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                )
            }

          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    leagues: openLeaguesByCity(state),
    player: currentPlayer(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadLeagues: (year?: string) => dispatch(loadOpenLeagues(year))
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeaguesPage));
